@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto+Condensed:400,700&display=swap);
body {
	font-family: 'Open Sans', sans-serif;
	background: #33363b;
	color: #FFF;
}

input, select {
	width: 100%;
}

.input-group-text {
	border: none;
	background: #33363b;
	color: #FFF;
}

.logo-text {
	text-align: center;
	padding-bottom: 0px;
	font-weight: 700;
}

.logo {
	width: 55px;
	padding-bottom: 10px;
}

.logo-nav {
	width: 30px;
}

.subheader {
	font-size: 18px;
}

.bgimg {
  height: 300px;
  background-image: url(/static/media/home-hero-image.5c6be920.jpg);
  background-attachment: fixed;
  background-position: center top;
}

.css-yk16xz-control {
	min-height: 55px;
}


.categories {
	display: flex;
	flex-wrap: wrap;
	padding: 0px;
}

.categories li {
	flex: 1 0 300px;
	list-style-type: none;
	width: 200px;
	height: 200px;
	background-color: #20123a;
	margin: 10px;
	text-align: center;
	padding-top: 100px;
	color: #FFF;
	font-size: 18px;
	font-weight: 700;
}

li.drizzy {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/drizzy-drake-bg.1d6ea39f.svg);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/drizzy-drake-bg.1d6ea39f.svg);
	background-size: cover;
}

#drizzy-drake.jumbotron-fluid {
	background-image: url(/static/media/drizzy-drake-hero-bg.25c25ffb.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.summa {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/summa-vibes-bg.ee34af7d.svg);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/summa-vibes-bg.ee34af7d.svg);
	background-size: cover;
}

#summa-vibes.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/summa-hero-bg.aa51b934.png);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/summa-hero-bg.aa51b934.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.travel {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(253,93,81,1)), to(rgba(255,190,0,0.6348914565826331))), url(/static/media/travel-bg.13ed110a.svg);
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url(/static/media/travel-bg.13ed110a.svg);
	background-size: cover;
	background-position: center center;
}

#travel.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(253,93,81,1)), to(rgba(255,190,0,0.6348914565826331))), url(/static/media/travel-hero-bg.84955489.png);
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url(/static/media/travel-hero-bg.84955489.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.myself {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/feelin-myself-bg.6cfd3c65.svg);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/feelin-myself-bg.6cfd3c65.svg);
	background-size: cover;
}

#feelin-myself.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/feelin-myself-hero-bg.2ae01e66.png);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/feelin-myself-hero-bg.2ae01e66.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.workout {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(253,93,81,1)), to(rgba(255,190,0,0.6348914565826331))), url(/static/media/workout-bg.e81e0f4f.svg);
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url(/static/media/workout-bg.e81e0f4f.svg);
	background-size: cover;
	background-position: center center;
}

#i-workout.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(253,93,81,1)), to(rgba(255,190,0,0.6348914565826331))), url(/static/media/workout-hero-bg.fb9add5d.png);
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url(/static/media/workout-hero-bg.fb9add5d.png);
	background-size: cover;
	background-position: center center;
	height: 500px;
}

li.bitch {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/boss-lady-bg.5d8d200f.svg);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/boss-lady-bg.5d8d200f.svg);
	background-size: cover;
}

#bo-bitch.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/boss-lady-hero-bg.d26884d0.png);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/boss-lady-hero-bg.d26884d0.png);
	background-size: cover;
	background-position: top;
	height: 500px;
}

li.night {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/night-out-bg.9f2e6bc2.svg);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/night-out-bg.9f2e6bc2.svg);
	background-size: cover;
}

#night-out.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/night-out-hero-bg.e20f13d0.png);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/night-out-hero-bg.e20f13d0.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.started {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/started-bottom-bg.e9e9e3f5.svg);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/started-bottom-bg.e9e9e3f5.svg);
	background-size: cover;
}

#started-from-the-bottom.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/started-bottom-hero-bg.94384e1c.png);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/started-bottom-hero-bg.94384e1c.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.moving {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(253,93,81,1)), to(rgba(255,190,0,0.6348914565826331))), url(/static/media/movin-on-bg.d2dbfb06.png);
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url(/static/media/movin-on-bg.d2dbfb06.png);
	background-size: cover;
	background-position: center center;
}

#moving-on-to-better-things.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(253,93,81,1)), to(rgba(255,190,0,0.6348914565826331))), url(/static/media/movin-on-hero-bg.fbe68887.png);
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url(/static/media/movin-on-hero-bg.fbe68887.png);
	background-size: cover;
	background-position: center center;
	height: 500px;
}

li.birthday {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/bday-vibes-bg.a3e77bf9.png);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/bday-vibes-bg.a3e77bf9.png);
	background-size: cover;
}

#birthday-vibez.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 246, 252, 0.52)), to(rgba(117, 19, 93, 0.73))), url(/static/media/bday-vibes-hero-bg.b0e9eb7b.png);
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(/static/media/bday-vibes-hero-bg.b0e9eb7b.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.squad {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/squad-goals-bg.2f42ff32.png);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/squad-goals-bg.2f42ff32.png);
	background-size: cover;
}

#squad-goals.jumbotron-fluid {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(128,102,217,0.5872724089635855)), to(rgba(199,0,255,0.6320903361344538))), url(/static/media/squad-goals-hero-bg.e8ba8420.png);
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url(/static/media/squad-goals-hero-bg.e8ba8420.png);
	background-size: cover;
	background-position: center;
	height: 500px;
}

.categories a {
	color: #FFF;
}

#lyricsTable {
	margin-top: 40px;
	color: #FFF;
}

.css-2b097c-container {
	color: #000;
}

.jumbotron h1 {
	text-align: center;
}
