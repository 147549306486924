@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto+Condensed:400,700&display=swap');

body {
	font-family: 'Open Sans', sans-serif;
	background: #33363b;
	color: #FFF;
}

input, select {
	width: 100%;
}

.input-group-text {
	border: none;
	background: #33363b;
	color: #FFF;
}

.logo-text {
	text-align: center;
	padding-bottom: 0px;
	font-weight: 700;
}

.logo {
	width: 55px;
	padding-bottom: 10px;
}

.logo-nav {
	width: 30px;
}

.subheader {
	font-size: 18px;
}

.bgimg {
  height: 300px;
  background-image: url('images/home-hero-image.jpg');
  background-attachment: fixed;
  background-position: center top;
}

.css-yk16xz-control {
	min-height: 55px;
}


.categories {
	display: flex;
	flex-wrap: wrap;
	padding: 0px;
}

.categories li {
	flex: 1 0 300px;
	list-style-type: none;
	width: 200px;
	height: 200px;
	background-color: #20123a;
	margin: 10px;
	text-align: center;
	padding-top: 100px;
	color: #FFF;
	font-size: 18px;
	font-weight: 700;
}

li.drizzy {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/drizzy-drake-bg.svg');
	background-size: cover;
}

#drizzy-drake.jumbotron-fluid {
	background-image: url('images/drizzy-drake-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.summa {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/summa-vibes-bg.svg');
	background-size: cover;
}

#summa-vibes.jumbotron-fluid {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/summa-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.travel {
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url('images/travel-bg.svg');
	background-size: cover;
	background-position: center center;
}

#travel.jumbotron-fluid {
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url('images/travel-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.myself {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/feelin-myself-bg.svg');
	background-size: cover;
}

#feelin-myself.jumbotron-fluid {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/feelin-myself-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.workout {
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url('images/workout-bg.svg');
	background-size: cover;
	background-position: center center;
}

#i-workout.jumbotron-fluid {
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url('images/workout-hero-bg.png');
	background-size: cover;
	background-position: center center;
	height: 500px;
}

li.bitch {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/boss-lady-bg.svg');
	background-size: cover;
}

#bo-bitch.jumbotron-fluid {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/boss-lady-hero-bg.png');
	background-size: cover;
	background-position: top;
	height: 500px;
}

li.night {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/night-out-bg.svg');
	background-size: cover;
}

#night-out.jumbotron-fluid {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/night-out-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.started {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/started-bottom-bg.svg');
	background-size: cover;
}

#started-from-the-bottom.jumbotron-fluid {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/started-bottom-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.moving {
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url('images/movin-on-bg.png');
	background-size: cover;
	background-position: center center;
}

#moving-on-to-better-things.jumbotron-fluid {
	background-image: linear-gradient(90deg, rgba(253,93,81,1) 0%, rgba(255,190,0,0.6348914565826331) 100%), url('images/movin-on-hero-bg.png');
	background-size: cover;
	background-position: center center;
	height: 500px;
}

li.birthday {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/bday-vibes-bg.png');
	background-size: cover;
}

#birthday-vibez.jumbotron-fluid {
	background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('images/bday-vibes-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

li.squad {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/squad-goals-bg.png');
	background-size: cover;
}

#squad-goals.jumbotron-fluid {
	background-image: linear-gradient(90deg, rgba(128,102,217,0.5872724089635855) 0%, rgba(199,0,255,0.6320903361344538) 100%), url('images/squad-goals-hero-bg.png');
	background-size: cover;
	background-position: center;
	height: 500px;
}

.categories a {
	color: #FFF;
}

#lyricsTable {
	margin-top: 40px;
	color: #FFF;
}

.css-2b097c-container {
	color: #000;
}

.jumbotron h1 {
	text-align: center;
}